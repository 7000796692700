import { useTranslation } from "next-i18next";
import { LanguagesCode } from "@config/i18n";
import { formatDate } from "@utils/format-date";

const useFormatDate = () => {
  const {
    i18n
  } = useTranslation();

  const handleFormatDate = (date: number | Date | string, format: string) => formatDate(date, format, (i18n.language as LanguagesCode));

  return handleFormatDate;
};

export default useFormatDate;